.breadcrumb {
  display: block;
  margin-bottom: 1.5rem;
  text-align: left;
  text-transform: lowercase;
}

.breadcrumb li, .breadcrumb a, .breadcrumb span {
  font-size: 0.9375rem;
  color: #B3B3B3;
}

.breadcrumb a, .breadcrumb span {
  display: block;
}

.breadcrumb a {
  text-decoration: none;
}

.breadcrumb ol {
  display: flex;
}

.breadcrumb ol li {
  display: flex;
  align-items: center;
}

.breadcrumb li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.breadcrumb li.seperator {
  padding: 0 5px;
}

.breadcrumb li.seperator span {
  display: block;
  font-size: 0;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #B3B3B3;
}

.breadcrumb li.current {
  font-weight: 700;
}
