@import "./_sass-essentials/essentials.scss";

.breadcrumb {
  display: block;
  margin-bottom: $base-spacing;
  text-align: left;
  text-transform: lowercase;

  li, a, span {
    @include resp-fs(15px);
    color: $c-bc-txt;
  }

  a, span {
    display: block;
  }

  a {
    text-decoration: none;
  }

  ol {
    display: flex;

    li {
      display: flex;
      align-items: center;
    }
  }

  li {
    list-style: none;
    margin: 0;
    padding: 0;

    &.seperator {
      padding: 0 5px;

      span {
        display: block;
        font-size: 0;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $c-bc-txt;
      }
    }

    &.current {
      font-weight: $fw-bold;
    }
  }
}
